<template>
  <div>
    <div class="login-form-container">
      <div class="login-register-form">
        <v-alert
          v-for="(error, idx) in getErrors"
          :key="idx"
          dense
          text
          type="error"
        >
          {{ error }}
        </v-alert>
        <v-alert
          v-show="is_success"
          dismissible
          dense
          text
          type="success"
          @input="closeAlert"
        >
          Confirma tu correo para iniciar sesion
        </v-alert>
        <form @submit.prevent="loginUser">
          <input
            v-model="user.email"
            type="text"
            name="email"
            placeholder="Email"
          />
          <input
            v-model="user.password"
            type="password"
            name="password"
            placeholder="Password"
          />
          <div class="button-box">
            <div class="login-toggle-btn">
              <router-link class="pb-3" :to="{ name: 'forget_password' }"
                >Olvidaste tu Contraseña?</router-link
              >
            </div>
            <v-btn
              color="primary"
              block
              depressed
              dark
              large
              :loading="isLoading"
              type="submit"
            >
              <span class="">Inicar Sesion</span>
            </v-btn>
          </div>
        </form>
        <v-btn
          class="my-6"
          @click="loginGoogle"
          type="button"
          depressed
          block
          dark
          large
          color="grey lighten-3"
        >
          <span class="google-button__icon mr-5">
            <svg
              style="height: 20px; width: 20px"
              viewBox="0 0 366 372"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z"
                id="Shape"
                fill="#EA4335"
              />
              <path
                d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z"
                id="Shape"
                fill="#FBBC05"
              />
              <path
                d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z"
                id="Shape"
                fill="#4285F4"
              />
              <path
                d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z"
                fill="#34A853"
              />
            </svg>
          </span>
          <p class="black--text text-h6 font-weight-bold">GOOGLE</p>
        </v-btn>
        <v-btn
          @click="logInWithFacebook"
          large
          dark
          block
          text
          class="grey lighten-3"
        >
          <i class="fa fa-facebook mr-5" style="color: #4d70a8"></i>
          <p class="black--text text-h6 font-weight-bold">FACEBOOK</p>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import * as yup from "yup";
import { initFacebookSdk } from "../helpers/helpers";
export default {
  name: "Login",
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      isLoading: false,
    };
  },
  head: {
    title: function () {
      return {
        inner: "Login",
      };
    },
  },
  computed: {
    ...mapState("auth", ["is_success"]),

    ...mapGetters("auth", ["getErrors"]),
  },

  async created() {
    initFacebookSdk().then();
    if (this.is_success) {
      setTimeout(() => {
        this.setIsSuccess(false);
      }, 10000);
    }
    this.setErrors("clear");
  },
  methods: {
    ...mapActions("auth", [
      "login",
      "loginSocial",
      "setErrors",
      "setIsSuccess",
    ]),
    closeAlert() {
      this.setIsSuccess(false);
    },
    async validateForm() {
      this.setErrors("clear");
      let schemaUser = yup.object().shape({
        email: yup
          .string()
          .email("El email debe ser válido")
          .required("El email es requerido"),
        password: yup.string().required("La conntraseña es requerida"),
      });

      try {
        await schemaUser.validate(this.user);
        return true;
      } catch (error) {
        this.setErrors(error.errors[0]);
        return false;
      }
    },
    async loginUser() {
      if (await this.validateForm()) {
        this.isLoading = true;
        await this.login(this.user);
        if (this.getErrors.length == 0) {
          this.isLoading = false;
        }
        this.isLoading = false;
      }
    },
    async loginGoogle() {
      // console.log("Login google");
      const googleUser = await this.$gAuth.signIn();
      await this.loginSocial({
        token: googleUser.getAuthResponse().id_token,
        provider: "google",
      }).catch((error) => {
        console.log(error);
        this.setErrors(error.details);
      });
    },
    async logInWithFacebook() {
      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
      window.FB.login(
        async (response) => {
          if (response.authResponse) {
            await this.loginFacebook(response.authResponse.accessToken);
            // console.log(response);
          } else {
            alert("User cancelled login or did not fully authorize.");
          }
        },
        { scope: "email" }
      );
    },

    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.VUE_APP_FACEBOOK_APP_ID,
          xfbml: true,
          cookie: true,
          version: "v12.0",
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
    async loginFacebook(final) {
      const user_login = {
        provider: "facebook",
        token: final,
        // cookie_web: c,
      };
      await this.loginSocial(user_login);
    },
  },
};
</script>

<style  scoped>
input:focus {
  border-color: #FAB848 !important;
}
</style>
